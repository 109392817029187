<script setup lang="ts">
const route = useRoute();
const storeHeader = useHeader();

// Computed
const white = computed(() => {
  switch (true) {
    default:
      return false;

    case route.name === 'quiz.home' || route.name === 'quiz.prepare':
      return true;
  }
});
const green = computed(() => {
  switch (true) {
    default:
      return false;

    case route.name === 'form.session' && route.params?.step !== 'sessie':
      return true;
    case route.name === 'quiz.session' &&
      (route.params?.step === 'klaar' || route.params?.step === 'afwezigheid'):
      return true;
    case route.name === 'quiz.results':
      return true;
  }
});
</script>

<template>
  <Header
    :menu="storeHeader.burger"
    :back="storeHeader.back"
    :close="storeHeader.close"
    @back="storeHeader.onBack"
    @close="storeHeader.onClose"
    logo="quiz"
    v-if="
      route.meta.hasOwnProperty('header')
        ? (route.meta.header as boolean)
        : true
    "
  />

  <main
    class="flex flex-grow flex-col"
    :class="{
      'with-bg-1': route.meta?.background === 1,
      'with-bg-2': route.meta?.background === 2,
      'with-bg-3': route.meta?.background === 3,
    }"
  >
    <div
      :class="{
        'flex flex-grow': true,
        'bg-[#6A3FAB]': !green && !white,
        '!bg-[#46A54F]': green,
      }"
    >
      <Container class="flex w-full flex-grow !px-0">
        <NuxtPage :key="route.fullPath" />
      </Container>
    </div>
  </main>
</template>

<style lang="css" scoped>
.with-bg-1 {
  @apply bg-right-bottom bg-no-repeat;
  background-image: url(/images/backgrounds/background-1.svg);
}
.with-bg-2 {
  background-image: url(/images/backgrounds/background-2.svg);
  background-repeat: no-repeat;
  background-position: bottom left;
}
.with-bg-3 {
  background-image: url(/images/backgrounds/background-3.svg);
  background-repeat: no-repeat;
  background-position: bottom left;
}
</style>
